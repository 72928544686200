:root {
  --font-termina: Termina;
  --font-acumin-pro: "Acumin Pro";
  --color-white: #fff;
  --color-mediumblue: #3b3bff;
  --color-black: #000;
  --color-mediumspringgreen: #00ef8b;
  --color-gray-100: #0a0a0a;
  --color-gray-200: #0000004d;
  --color-whitesmoke: #f5f5f5;
}

@font-face {
  font-family: Termina;
  src: url("Termina-Thin.a6496429.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Termina;
  src: url("Termina-ExtraLight.7f6b0347.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Termina;
  src: url("Termina-Light.8cd80876.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Termina;
  src: url("Termina-Regular.4d209d86.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Termina;
  src: url("Termina-Medium.ef1e9f62.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Termina;
  src: url("Termina-Demi.ace1be5f.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Termina;
  src: url("Termina-Bold.b9423712.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Termina;
  src: url("Termina-Heavy.c88e3777.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Termina;
  src: url("Termina-Black.b441eed8.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Acumin Pro;
  src: url("Acumin Pro Thin.2d33bc14.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Acumin Pro;
  src: url("Acumin Pro ExtraLight.827c4e93.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Acumin Pro;
  src: url("Acumin Pro Light.0fcb1e28.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Acumin Pro;
  src: url("Acumin Pro Book.4912ea46.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Acumin Pro;
  src: url("Acumin Pro Medium.6735c910.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Acumin Pro;
  src: url("Acumin Pro Semibold.063a2922.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Acumin Pro;
  src: url("Acumin Pro Bold.0c8691a3.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Acumin Pro;
  src: url("Acumin Pro Black.fbcd1d79.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Acumin Pro;
  src: url("Acumin Pro UltraBlack.cfda61e6.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

/*# sourceMappingURL=index.93e93cb3.css.map */
