:root {
  /* fonts */
  --font-termina: Termina;
  --font-acumin-pro: "Acumin Pro";

  /* Colors */
  --color-white: #fff;
  --color-mediumblue: #3b3bff;
  --color-black: #000;
  --color-mediumspringgreen: #00ef8b;
  --color-gray-100: #0a0a0a;
  --color-gray-200: rgba(0, 0, 0, 0.3);
  --color-whitesmoke: #f5f5f5;
}

/* Termina */

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/Termina/Termina-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/Termina/Termina-ExtraLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/Termina/Termina-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/Termina/Termina-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/Termina/Termina-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/Termina/Termina-Demi.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/Termina/Termina-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/Termina/Termina-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/Termina/Termina-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

/* Acumin Pro */

@font-face {
  font-family: "Acumin Pro";
  src: url("../public/fonts/Acumin/Acumin\ Pro\ Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../public/fonts/Acumin/Acumin\ Pro\ ExtraLight.otf")
    format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../public/fonts/Acumin/Acumin\ Pro\ Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../public/fonts/Acumin/Acumin\ Pro\ Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../public/fonts/Acumin/Acumin\ Pro\ Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../public/fonts/Acumin/Acumin\ Pro\ Semibold.otf")
    format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../public/fonts/Acumin/Acumin\ Pro\ Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../public/fonts/Acumin/Acumin\ Pro\ Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../public/fonts/Acumin/Acumin\ Pro\ UltraBlack.otf")
    format("opentype");
  font-weight: 800;
  font-style: normal;
}
